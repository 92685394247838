import * as Tokens from '@wisereducacao-npm/design-system-tokens/dist/esm/pwh/dark/dark';
import {
    border,
    font as globalFontStyles,
    lineHeight,
    opacity,
    spacing,
} from '../global';

const color = {
  primary: {
    dark: Tokens.ColorPrimaryDark,
    light: Tokens.ColorPrimaryLight,
    medium: Tokens.ColorPrimaryMedium,
    pure: Tokens.ColorPrimaryPure,
  },
  secondary: {
    dark: Tokens.ColorSecondaryDark,
    light: Tokens.ColorSecondaryLight,
    medium: Tokens.ColorSecondaryMedium,
    pure: Tokens.ColorSecondaryPure,
  },
  neutral: {
    button: Tokens.NeutralColorButton,
    highContrast: Tokens.NeutralColorHighContrast,
    lowContrast: Tokens.NeutralColorLowContrast,
    mediumContrast: Tokens.NeutralColorMediumContrast,
    pure: Tokens.NeutralColorPure,
  },
  feedback: {
    success: {
      highContrast: Tokens.FeedbackColorSuccessHighContrast,
      lowContrast: Tokens.FeedbackColorSuccessLowContrast,
      mediumContrast: Tokens.FeedbackColorSuccessMediumContrast,
      pure: Tokens.FeedbackColorSuccessPure,
    },
    alert: {
      highContrast: Tokens.FeedbackColorAlertHighContrast,
      lowContrast: Tokens.FeedbackColorAlertLowContrast,
      mediumContrast: Tokens.FeedbackColorAlertMediumContrast,
      pure: Tokens.FeedbackColorAlertPure,
    },
    error: {
      highContrast: Tokens.FeedbackColorErrorHighContrast,
      lowContrast: Tokens.FeedbackColorErrorLowContrast,
      mediumContrast: Tokens.FeedbackColorErrorMediumContrast,
      pure: Tokens.FeedbackColorErrorPure,
    },
    button: Tokens.FeedbackColorButton,
  },
};

const font = {
  ...globalFontStyles,
  family: {
    primary: Tokens.FontFamilyPrimary,
  },
};

export const newPowerhouse = {
  color,
  spacing,
  font,
  lineHeight,
  border,
  opacity,
  data: {
    dsProductId: 'pwh',
    business: 'powerhouse',
    productCode: 'pwh',

    isTermsAndPoliticsAvailable: true,
    isThemeUsingAllDsTokens: true,
    isProductFlowsAvailable: true,
    googleTagManagerId: 'GTM-MCGBGJW',
    lang: 'pt-BR',
    faqLink: 'https://powerhouse.pro/faq',
    logoFile: 'logo-pwh.svg',
    stepsNumber: 5,
    product: {
      bffId: 'powerhouse',
      name: 'Power House',
      businessName: 'Power House',
      plans: {
        anualProductId: '1dd987ce-332a-11ea-978f-2e728ce88125',
        monthlyProductId: '',
      },
      price: {
        productCyclePrice: 48,
        productFullPrice: 576,
        productStandardCycle: 48,
        productStandardPrice: 576,
      },
    },
    payment: {
      currentStep: 4,
      cardCompanies: [
        { value: 'mastercard', name: 'Mastercard' },
        { value: 'visa', name: 'Visa' },
        { value: 'american_express', name: 'Amex' },
        { value: 'diners', name: 'Diners' },
        { value: 'elo', name: 'Elo' },
      ],
      planTypes: {
        yearly: 'yearly',
        recurrency: 'recurrency',
      },
      sendBffPaymentRequest: {
        documentToNFGeneration: false,
      },
      productDsIcon: 'desktop',
    },
    success: {
      finalSuccess: {
        displayHeader: {
          bankslip: true,
          default: true,
        },
        displayIcon: true,
        displayAccessButton: true,
      },
    },
    links: {
      test: {
        domain: 'https://test.powerhouse.pro',
        firstAccess:
          'https://test-app.powerhouse.pro/finalizar-cadastro?t=${token}',
        terms: 'https://powerhouse.pro/termos-uso/',
        politics: 'https://powerhouse.pro/politica-privacidade/',
      },
      homolog: {
        domain: 'https://powerhouse.pro',
        firstAccess: 'https://app.powerhouse.pro/finalizar-cadastro?t=${token}',
        terms: 'https://powerhouse.pro/termos-uso/',
        politics: 'https://powerhouse.pro/politica-privacidade/',
      },
      production: {
        domain: 'https://powerhouse.pro',
        firstAccess: 'https://app.powerhouse.pro/finalizar-cadastro?t=${token}',
        terms: 'https://powerhouse.pro/termos-uso/',
        politics: 'https://powerhouse.pro/politica-privacidade/',
      },
    },
  },

  style: {
    form: {
      maxWidth: '485px',
    },
    assets: {
      logo: {
        file: 'logo-pwh.svg',
        width: '153px',
      },
      favicon: 'favicon-powerhouse.ico',
      loading: 'rolling-pwh.svg',
    },
  },

  text: {
    sellerBox: 'Atendimento',
    lead: {
      text: 'Informe seus dados',
      description:
        'Complete suas informações para prosseguir para o pagamento.',
      button: 'Continuar',
      labels: {
        name: 'Nome Completo',
        email: 'E-mail',
        phone: 'Celular',
      },
      placeholder: {
        name: 'Ex: João Silva',
        email: 'Ex: seuemail@gmail.com',
        phone: 'Ex: 41999999999',
      },
    },
    payment: {
      title: 'Garanta o Power House 23 Pay per View',
      description:
        'Para assistir à transmissão online AO VIVO, no dia 07/01/23 e ganhar todas as edições anteriores por 1 ano, preencha os dados do seu cartão de crédito abaixo:',
      button: 'Confirmar pagamento',
      labels: {
        cardCompany: 'Selecione sua bandeira',
        cardName: 'Nome do titular',
        cardNumber: 'Número do cartão',
        cardExpireDate: 'Data de validade',
        cardCVV: 'CVV',
        postalCode: 'CEP',
        addressLine1: 'Rua',
        addressLine2: 'Número',
        addressLine3: 'Complemento',
        city: 'Cidade',
        state: 'Estado',
        country: 'País',
      },
      placeholder: {
        cardCompany: 'Selecione',
        cardName: 'Ex: João Silva',
        cardNumber: '**** **** **** ****',
        cardExpireDate: 'MM/AA',
        cardCVV: 'xxx',
        postalCode: 'xxxxx xxxx',
        addressLine1: 'xxxxx',
        addressLine2: 'xxxxx',
        addressLine3: 'xxxxx',
        city: 'Cidade',
        state: 'Estado',
        country: 'País',
      },
    },
    success: {
      default: {
        title: 'Compra realizada com sucesso!',
        subtitle:
          'Seu acesso já está liberado! Nos vemos no Power House 2023. Aproveite a experiência!<br /><br /><br />',
        icon: 'check_circle',
        button: {
          text: 'Ver as edições anteriores',
        },
      },
      bankslip: {
        title: 'Quase lá!',
        subtitle:
          'Seu boleto já está disponível para pagamento. Você pode acessá-lo abaixo. <br /> <br /> Em até 2 dias após a confirmação do pagamento, você receberá um e-mail para finalizar seu cadastro.<br /><br /><br />',
        icon: 'smiley',
        button: {
          text: 'Ver boleto',
        },
      },
    },
  },
} as const;
