import { api } from '../../config/base-api';

export type ValidateCustomSubscriptionCardParams = {
  subscriptionId: string;
  cardData: {
    card: {
      company: string;
      holderName: string;
      number: string;
      cvv: string;
      expiryMonth: string;
      expiryYear: string;
    };
  };
};

export type ValidateCustomSubscriptionCardResponse = {
  isValid: boolean;
};

export async function validateCustomSubscriptionCard(
  { subscriptionId, cardData }: ValidateCustomSubscriptionCardParams,
  isServer?: boolean
): Promise<ValidateCustomSubscriptionCardResponse> {
  const requestUrl = isServer
    ? `http://localhost:3000/api/v2/subscription/${subscriptionId}/custom-sale/verify-card`
    : `/api/v2/subscription/${subscriptionId}/custom-sale/verify-card`;

  const res = await api.post<ValidateCustomSubscriptionCardResponse>(
    requestUrl,
    cardData
  );

  return res.data;
}
