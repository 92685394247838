import { ProviderBrandAttribute } from '@wisereducacao-npm/design-system-brands-web/dist/components/provider/types';

// hooks
import { ProductThemeProps } from 'hooks/useCart';
import { allAvailableProductThemes } from '../hooks/useProductTheme';

// types
import { Business, PlanType } from 'types';

export const pageTemplates = [
  { id: 'lead', route: '/lead' },
  { id: 'phone-verification', route: '/phone-verification' },
  { id: 'video', route: '/video' },
  { id: 'upsell-video', route: '/video/upsell' },
  { id: 'order-bump-presentation', route: '/multiprofile-presentation' },
  { id: 'order-bump', route: '/order-bump-presentation' },
  { id: 'presentation', route: '/presentation' },
  {
    id: 'upsell-order-bump-presentation',
    route: '/multiprofile-upsell-presentation',
  },
  { id: 'payment', route: '/payment' },
  { id: 'custom-offer', route: '/custom-offer' },
  { id: 'success', route: '/success' },
  { id: 'upsell-payment', route: '/payment/upsell' },
  { id: 'upsell-success', route: '/success/upsell' },
  { id: 'pix', route: '/pix' },
  { id: 'livelo-cpf', route: '/livelo/cpf' },
  { id: 'invoice-payment', route: '/payment/[id]' },
  { id: 'recovery', route: '/recovery/[id]' },
  { id: 'renew', route: '/renew/[id]' },
  { id: 'error404', route: '/generic-error' },
  { id: 'error', route: '/generic-error' },
  { id: 'select-school', route: '/select-school' },
  { id: 'upgrade-payment', route: '/payment/upgrade' },
];

type ProductFlowSteps =
  | 'lead'
  | 'multiprofile'
  | 'main-payment'
  | 'main-success'
  | 'custom-offer'
  | 'upsell-payment'
  | 'upsell-success'
  | 'pix'
  | 'upsell-multiprofile'
  | 'livelo'
  | 'video'
  | 'upsell-video'
  | 'trial'
  | 'trial-already-used';

type ProductFlowTemplates =
  | 'lead'
  | 'order-bump-presentation'
  | 'presentaion'
  | 'order-bump'
  | 'upsell-order-bump-presentation'
  | 'payment'
  | 'success'
  | 'custom-offer'
  | 'upsell-payment'
  | 'upsell-success'
  | 'pix'
  | 'livelo-cpf'
  | 'invoice-payment'
  | 'recovery'
  | 'renew'
  | 'error404'
  | 'error'
  | 'select-school'
  | 'upgrade-payment';

export interface ProductPlan {
  id: string;
  isMostPopular?: boolean;
  /**
   * A ideia futura é removermos o plan, planType, business e productCode,
   * deixando apenas o planId
   */
  business: string;
  productCode: string;
  plan: string;
  planType: string;
  planName?: string;
  buttonLabel?: string;
  disabled?: boolean;
  dsIcon?: string;
  standardAmount?: number;
}

export interface ProductFlowStep {
  defaultNextStepId: ProductFlowSteps;
  id: ProductFlowSteps;
  props?: {
    /**
     * Exibe a comunicação do checkbox de "Termos do contrato" na tela de pagamento
     */
    showContractTermsCommunicationCheckbox?: boolean;
    termsUrl?: string;
    politicsUrl?: string;
    enableAllDocumentsForInvoice?: boolean;
    countdownTimerSeconds?: number;
    enableInstallmentsForAllPaymentMethods?: boolean;
    shouldRequestDocument?: boolean;
    /**
     * Habilita a geração externa de contrato na tela de pagamento
     */
    useCheckboxContractTerms?: boolean;
    orderBump?: {
      plan: {
        id: string;
        productCode: string;
        /**
         * A ideia futura é removermos o plan, planType, business e productCode,
         * deixando apenas o planId
         */
        plan: string;
        planType: PlanType;
        business: Business;
      };
      content: {
        logo: string;
        cardText: string;
        cardResume: string;
        checkboxText: string;
      };
    };
    displayContractCheckbox: boolean;
    /**
     * @deprecated
     * estamos migrando incrementalmente para que a configuração dos métodos
     * de pagamento seja realizada no Sales Force, a nivel do plano, e não mais no checkout-flows.
     */
    paymentMethods: { id: string }[];
    /**
     * @description
     * Caso essa prop seja setada, vai sobrescrever os métodos de pagamento vindo
     * do plano do produto.
     */
    paymentMethodsOverride: { id: string }[];
    showBirthDateInput: boolean;
    showDocumentInput: boolean;
    showResponsibleInputs: boolean;
    hideTermsUrl: boolean;
    descriptionTitle?: string;
    description: string;
    secondaryButtonLabel?: string;
    primaryButtonLabel?: string;
    media: {
      source: string;
      logoCard?: string;
    };
    upsell?: {
      description: string;
      hideSecondaryButton?: boolean;
      infoText?: string;
    };
    cardDescription: string;
    buttonLabel: string;
    icon: string;
    subtitle: string;
    title: string;
    productTitle: string;
    productDescription: string;
    productDisplayName: string;
    plan: ProductPlan;
    plans: ProductPlan[];
    voucher?: string;
    redirect?: string;
    displayVoucherInput?: boolean;
    forceDisplayVoucherInput?: boolean;
    displayRenewAutomaticallyCheckbox: boolean;
    business: Business;
    productCode: string;
    hideButton: boolean;
    defaultSelectedInstallment: number;
    removeCardBorder?: boolean;
    useNeutralPureColorForText?: boolean;
    useNewPlansFormat?: boolean;
    externalLandingPageOffer?: {
      src: string;
    };
    externalPaymentPage?: {
      src: string;
    };
  };
  templateId: ProductFlowTemplates;
}

export interface ProductFlowProps {
  id: string;
  initialStep: ProductFlowSteps;
  version: string;
  query: {
    [key: string]: string;
  };
  steps: ProductFlowStep[];
  props: {
    main: {
      business: string;
      productCode: string;
      logo: string;
      name: string;
      dsProductId: ProviderBrandAttribute;
    };
    upsell: {
      business: string;
      productCode: string;
      logo: string;
      name: string;
      presentationImg: string;
      multiProfilePresentationImg?: string;
      dsProductId: ProviderBrandAttribute;
      dsMode: 'dark' | 'light';
      /**
       * Se existir esse parâmetro, entende-se que deseja redirecionar
       * o usuário para uma conversa no whatsapp ao clicar na oferta do upsell
       */
      whatsAppRedirect?: string;
    };
    shouldApplyBlackNovemberImplementation?: boolean;
  };
}

interface GetCurrentStepProps {
  productFlow: ProductFlowProps;
  pathname: string;
}

export const getCurrentStep = ({
  productFlow,
  pathname = '',
}: GetCurrentStepProps) => {
  const currentTemplate = pageTemplates.find((item) => item.route === pathname);
  const currentStepProps = productFlow?.steps?.find(
    (step) => step.templateId === currentTemplate?.id
  );

  return {
    ...currentStepProps,
    route: currentTemplate?.route,
  };
};

interface GetNextStepProps extends GetCurrentStepProps {}

export const getNextStep = ({
  productFlow,
  pathname = '',
}: GetNextStepProps) => {
  const currentStepProps = getCurrentStep({
    productFlow,
    pathname,
  });

  const nextStepProps = productFlow?.steps.find(
    (item) => item.id === currentStepProps?.defaultNextStepId
  );
  const nextTemplate = pageTemplates.find(
    (item) => item.id === nextStepProps?.templateId
  );

  return {
    ...nextStepProps,
    route: nextTemplate?.route,
  };
};

interface GetUpsellThemeByProductFlowProps {
  productFlow: ProductFlowProps;
}

export const getUpsellThemeByProductFlow = ({
  productFlow,
}: GetUpsellThemeByProductFlowProps) => {
  const upsellDsProductId = productFlow?.props?.upsell?.dsProductId;

  const upsellTheme = allAvailableProductThemes?.find((theme) => {
    const themeData = theme as unknown as ProductThemeProps;

    return themeData?.data?.dsProductId === upsellDsProductId;
  });

  return upsellTheme as unknown as ProductThemeProps;
};

export const getProductFlowStepById = (
  stepId: ProductFlowSteps,
  productFlow: ProductFlowProps
) => {
  return productFlow?.steps?.find((step) => step?.id === stepId);
};
