import * as Tokens from '@wisereducacao-npm/design-system-tokens/dist/esm/ms/dark/dark';
import {
    border,
    font as globalFontStyles,
    lineHeight,
    opacity,
    spacing,
} from '../global';

const color = {
  primary: {
    dark: Tokens.ColorPrimaryDark,
    light: Tokens.ColorPrimaryLight,
    medium: Tokens.ColorPrimaryMedium,
    pure: Tokens.ColorPrimaryPure,
  },
  secondary: {
    dark: Tokens.ColorSecondaryDark,
    light: Tokens.ColorSecondaryLight,
    medium: Tokens.ColorSecondaryMedium,
    pure: Tokens.ColorSecondaryPure,
  },
  neutral: {
    button: Tokens.NeutralColorButton,
    highContrast: Tokens.NeutralColorHighContrast,
    lowContrast: Tokens.NeutralColorLowContrast,
    mediumContrast: Tokens.NeutralColorMediumContrast,
    pure: Tokens.NeutralColorPure,
  },
  feedback: {
    success: {
      highContrast: Tokens.FeedbackColorSuccessHighContrast,
      lowContrast: Tokens.FeedbackColorSuccessLowContrast,
      mediumContrast: Tokens.FeedbackColorSuccessMediumContrast,
      pure: Tokens.FeedbackColorSuccessPure,
    },
    alert: {
      highContrast: Tokens.FeedbackColorAlertHighContrast,
      lowContrast: Tokens.FeedbackColorAlertLowContrast,
      mediumContrast: Tokens.FeedbackColorAlertMediumContrast,
      pure: Tokens.FeedbackColorAlertPure,
    },
    error: {
      highContrast: Tokens.FeedbackColorErrorHighContrast,
      lowContrast: Tokens.FeedbackColorErrorLowContrast,
      mediumContrast: Tokens.FeedbackColorErrorMediumContrast,
      pure: Tokens.FeedbackColorErrorPure,
    },
    button: Tokens.FeedbackColorButton,
  },
};

const font = {
  ...globalFontStyles,
  family: {
    primary: Tokens.FontFamilyPrimary,
  },
};

export const newMeusucesso = {
  color,
  spacing,
  font,
  lineHeight,
  border,
  opacity,
  data: {
    business: 'meusucesso',
    productCode: 'ms',
    dsProductId: 'ms',
    isTermsAndPoliticsAvailable: true,

    isThemeUsingAllDsTokens: true,
    isProductFlowsAvailable: true,
    googleTagManagerId: 'GTM-T2GZZWL',
    logoFile: 'logo-ms.png',
    lang: 'pt-BR',
    faqLink: 'https://meusucesso.com/faq',
    stepsNumber: 4,
    product: {
      bffId: 'meusucesso',
      name: 'Meu Sucesso',
      businessName: 'meuSucesso.com',
    },
    payment: {
      currentStep: 3,
      cardCompanies: [
        { value: 'american_express', name: 'Amex' },
        { value: 'visa', name: 'Visa' },
        { value: 'mastercard', name: 'Mastercard' },
        { value: 'elo', name: 'Elo' },
        { value: 'diners', name: 'Diners' },
        { value: 'hipercard', name: 'Hipercard' },
      ],
      planTypes: {
        yearly: 'yearly',
        recurrency: 'recurrency',
      },
      sendBffPaymentRequest: {
        documentToNFGeneration: false,
      },
      productDsIcon: 'user',
    },
    success: {
      finalSuccess: {
        displayHeader: {
          bankslip: true,
          default: true,
        },
        displayIcon: true,
        displayAccessButton: true,
      },
    },
    links: {
      test: {
        domain: 'https://test-v2.meusucesso.com',
        firstAccess:
          'https://test-v2.meusucesso.com/primeiro-acesso?source=email&token=${token}',
        politics: 'https://meusucesso.com/politica-de-privacidade/',
        terms: 'https://meusucesso.com/termos-de-servico',
      },
      homolog: {
        domain: 'https://v2.meusucesso.com',
        firstAccess:
          'https://v2.meusucesso.com/primeiro-acesso?source=email&token=${token}',
        politics: 'https://meusucesso.com/politica-de-privacidade/',
        terms: 'https://meusucesso.com/termos-de-servico',
      },
      production: {
        domain: 'https://v2.meusucesso.com',
        firstAccess:
          'https://v2.meusucesso.com/primeiro-acesso?source=email&token=${token}',
        politics: 'https://meusucesso.com/politica-de-privacidade/',
        terms: 'https://meusucesso.com/termos-de-servico',
      },
    },
  },
  style: {
    form: {
      maxWidth: '485px',
    },
    assets: {
      logo: {
        file: 'logo-ms.png',
        width: '220px',
      },
      favicon: 'favicon-meusucesso.png',
      loading: 'rolling-ms.svg',
    },
  },
  text: {
    sellerBox: 'Atendimento:',
    lead: {
      text: 'Informe seus dados',
      description:
        'Complete suas informações para prosseguir para o pagamento.',
      button: 'Continuar',
      labels: {
        name: 'Nome Completo',
        email: 'E-mail',
        phone: 'Whatsapp',
      },
      placeholder: {
        name: 'Ex: João Silva',
        email: 'Ex: seuemail@gmail.com',
        phone: 'Ex: 41999999999',
      },
    },
    payment: {
      title: 'Falta pouco pra você começar a estudar',
      description:
        'Você está prestes a adquirir o <b class="no-line-break-word">{PRODUCT_NAME}</b>. Escolha a forma de pagamento para concluir sua compra.',
      button: 'Confirmar pagamento',
      upsell50: {
        title: '50% de desconto<br/><br/>',
        subtitle:
          'para você, aluno do Wise Up Online, estudar no meuSucesso<br/><br/>',
        description:
          'Aproveite o voucher exclusivo para aprender com os maiores players do mercado, selecione seu plano anual e comece agora mesmo.',
      },
      labels: {
        cardCompany: 'Selecione sua bandeira',
        cardName: 'Nome do titular',
        cardNumber: 'Número do cartão',
        cardExpireDate: 'Data de validade',
        cardCVV: 'CVV',
        postalCode: 'CEP',
        addressLine1: 'Rua',
        addressLine2: 'Número',
        addressLine3: 'Complemento',
        city: 'Cidade',
        state: 'Estado',
        country: 'País',
      },
      placeholder: {
        cardCompany: 'Selecione',
        cardName: 'Ex: João Silva',
        cardNumber: '**** **** **** ****',
        cardExpireDate: 'MM/AA',
        cardCVV: 'xxx',
        postalCode: 'xxxxx xxxx',
        addressLine1: 'xxxxx',
        addressLine2: 'xxxxx',
        addressLine3: 'xxxxx',
        city: 'Cidade',
        state: 'Estado',
        country: 'País',
      },
    },
    success: {
      default: {
        title:
          'Seja bem-vindo(a) ao <b class="no-line-break-word">{MAIN_PRODUCT_NAME}</b>',
        subtitle:
          'O seu acesso anual já está disponível! Clique no botão abaixo e continue para seu primeiro acesso.<br /><br /><br />',
        icon: 'check_circle',
        button: {
          text: 'Acessar o {MAIN_PRODUCT_NAME}',
        },
      },
      bankslip: {
        title: 'Quase lá!',
        subtitle:
          'Seu boleto já está disponível para pagamento. Você pode acessá-lo abaixo. <br /> <br /> Em até 2 dias após a confirmação do pagamento, você receberá um e-mail para finalizar seu cadastro.<br /><br /><br />',
        icon: 'smiley',
        button: {
          text: 'Ver boleto',
        },
      },
    },
  },
} as const;
