import { api } from '../config/base-api';

interface GetRegionsParams {
  country: string;
  brand: string;
  isConquerFranquias?: boolean;
}

export interface GetRegionsResponse {
  brand: string;
  country: string;
  region: string;
}

export async function getRegions({
  brand,
  country,
  isConquerFranquias = false,
}: GetRegionsParams): Promise<GetRegionsResponse[]> {
  if (isConquerFranquias) {
    return [{ region: 'PR', brand, country: 'BR' }];
  }

  const res = await api.get<GetRegionsResponse[]>(
    '/api/v1/franchises/regions',
    {
      params: {
        brand,
        country,
      },
    }
  );

  return res.data;
}
