import { Business } from 'types';
import { api } from '../config/base-api';

export interface GetContractParams {
  business: Business;
  planId?: string;
}

export async function getContract(
  code: string,
  params: GetContractParams
): Promise<string> {
  const response = await api.get(`/api/v1/product/${code}/contract`, {
    params,
  });

  return response.data;
}
