import { api } from '../config/base-api';

interface GetCitiesParams {
  country: string;
  brand: string;
  region: string;
  isConquerFranquias?: boolean;
}

export interface GetCitiesResponse {
  brand: string;
  country: string;
  region: string;
  city: string;
}

export async function getCities({
  brand,
  country,
  region,
  isConquerFranquias = false,
}: GetCitiesParams): Promise<GetCitiesResponse[]> {
  if (isConquerFranquias) {
    return [{ city: 'Curitiba', brand, country: 'BR', region: 'PR' }];
  }

  const res = await api.get<GetCitiesResponse[]>('/api/v1/franchises/cities', {
    params: {
      brand,
      country,
      region,
    },
  });

  return res.data;
}
