import { api } from '../config/base-api';

import {
  CONQUER_FRANQUIAS_SCHOOL_UNIT_ID_PROD,
  CONQUER_FRANQUIAS_SCHOOL_UNIT_ID_DEV,
} from 'screens/DS/SelectSchool/constants';

const isProductionEnv = () => {
  const env = process.env.APPLICATION_ENV;
  return env === 'production' || env === 'homolog';
};

interface GetUnitsParams {
  country: string;
  brand: string;
  region: string;
  city: string;
  isConquerFranquias?: boolean;
}

export interface GetUnitsResponse {
  /** Esse ID é o accountId da unidade selecionada */
  salesforceId: string;
  name: string;
}

export async function getUnits({
  brand,
  country,
  region,
  city,
  isConquerFranquias = false,
}: GetUnitsParams): Promise<GetUnitsResponse[]> {
  if (isConquerFranquias) {
    const isProd = isProductionEnv();
    return [
      {
        salesforceId: isProd
          ? CONQUER_FRANQUIAS_SCHOOL_UNIT_ID_PROD
          : CONQUER_FRANQUIAS_SCHOOL_UNIT_ID_DEV,
        name: 'Conquer Agua Verde',
      },
    ];
  }

  const res = await api.get<GetUnitsResponse[]>('/api/v1/franchises/units', {
    params: {
      brand,
      country,
      region,
      city,
    },
  });

  return res.data;
}
