import * as Tokens from '@wisereducacao-npm/design-system-tokens/dist/esm/wol/dark/dark';
import {
    border,
    font as globalFontStyles,
    lineHeight,
    opacity,
    spacing,
} from '../global';

const color = {
  primary: {
    dark: Tokens.ColorPrimaryDark,
    light: Tokens.ColorPrimaryLight,
    medium: Tokens.ColorPrimaryMedium,
    pure: Tokens.ColorPrimaryPure,
  },
  secondary: {
    dark: Tokens.ColorSecondaryDark,
    light: Tokens.ColorSecondaryLight,
    medium: Tokens.ColorSecondaryMedium,
    pure: Tokens.ColorSecondaryPure,
  },
  neutral: {
    button: Tokens.NeutralColorButton,
    highContrast: Tokens.NeutralColorHighContrast,
    lowContrast: Tokens.NeutralColorLowContrast,
    mediumContrast: Tokens.NeutralColorMediumContrast,
    pure: Tokens.NeutralColorPure,
  },
  feedback: {
    success: {
      highContrast: Tokens.FeedbackColorSuccessHighContrast,
      lowContrast: Tokens.FeedbackColorSuccessLowContrast,
      mediumContrast: Tokens.FeedbackColorSuccessMediumContrast,
      pure: Tokens.FeedbackColorSuccessPure,
    },
    alert: {
      highContrast: Tokens.FeedbackColorAlertHighContrast,
      lowContrast: Tokens.FeedbackColorAlertLowContrast,
      mediumContrast: Tokens.FeedbackColorAlertMediumContrast,
      pure: Tokens.FeedbackColorAlertPure,
    },
    error: {
      highContrast: Tokens.FeedbackColorErrorHighContrast,
      lowContrast: Tokens.FeedbackColorErrorLowContrast,
      mediumContrast: Tokens.FeedbackColorErrorMediumContrast,
      pure: Tokens.FeedbackColorErrorPure,
    },
    button: Tokens.FeedbackColorButton,
  },
};

const font = {
  ...globalFontStyles,
  family: {
    primary: Tokens.FontFamilyPrimary,
  },
};

export const newTravel = {
  color,
  spacing,
  font,
  lineHeight,
  border,
  opacity,
  data: {
    business: 'wiseup-online',
    productCode: 'wol',
    dsProductId: 'wol',

    isTermsAndPoliticsAvailable: true,
    isThemeUsingAllDsTokens: true,
    isProductFlowsAvailable: true,
    googleTagManagerId: 'GTM-KD85DXP',
    stepsNumber: 5,
    // @TODO: unify with assets.logo.file
    /** @deprecated */
    logoFile: 'logo-wup.svg',
    lang: 'pt-BR',
    faqLink: 'https://wiseup.com/faq/',
    product: {
      bffId: 'wiseup-online',
      name: 'Wise Up Online',
      businessName: 'Wise Up Online',
    },
    /** @deprecated */
    refererArray: [
      {
        name: 'Site Wise Up',
        id: 'wiseup-site',
        datacy: '@success/sitewiseup-button',
      },
      {
        name: 'Escola Wise Up',
        id: 'wiseup-escola',
        datacy: '@sucess/escolawiseup-button',
      },
      { name: 'Email', id: 'email', datacy: '@success/email-button' },
      { name: 'Facebook', id: 'facebook', datacy: '@sucess/facebook-button' },
      { name: 'Google', id: 'google', datacy: '@sucess/google-button' },
      {
        name: 'Instagram',
        id: 'instagram',
        datacy: '@sucess/instagram-button',
      },
      { name: 'Youtube', id: 'youtube', datacy: '@sucess/youtube-button' },
      {
        name: 'Através de um representante',
        id: 'representante',
        datacy: '@sucess/representative-button',
      },
      {
        name: 'Adriana e Rodrigão',
        id: 'adrianaerodrigao',
        datacy: '@sucess/adrianaerodrigao-button',
      },
      {
        name: 'Flávio Augusto',
        id: 'flavioaugusto',
        datacy: '@sucess/flavioaugusto-button',
      },
      {
        name: 'Jovem Nerd',
        id: 'jovemnerd',
        datacy: '@sucess/jovemnerd-button',
      },
      {
        name: 'Thiago Nigro',
        id: 'thiagonigro',
        datacy: '@sucess/thiagonigro-button',
      },
      {
        name: 'Livelo',
        id: 'livelo',
        datacy: '@sucess/livelo-button',
      },
      { name: 'Outro', id: 'outro', datacy: '@sucess/outro-button' },
    ],
    multiProfile: {
      cardThumbnailFile: '/static/multiprofileUpsell.png',
      shouldUseMonthlyDifference: true,
    },
    payment: {
      currentStep: 4,
      cardCompanies: [
        { value: 'american_express', name: 'Amex' },
        { value: 'visa', name: 'Visa' },
        { value: 'mastercard', name: 'Mastercard' },
        { value: 'elo', name: 'Elo' },
        { value: 'diners', name: 'Diners' },
        { value: 'hipercard', name: 'Hipercard' },
      ],
      planTypes: {
        yearly: 'yearly',
        recurrency: 'recurrency',
      },
      sendBffPaymentRequest: {
        documentToNFGeneration: false,
      },
      productDsIcon: 'user',
      whatsAppRedirect:
        'https://wa.me/554188023266?text=Quero saber mais sobre minha condição exclusiva para fazer as aulas de conversação do Wise Up Online.',
    },
    success: {
      finalSuccess: {
        displayHeader: {
          bankslip: true,
          default: true,
        },
        displayIcon: true,
        displayAccessButton: true,
      },
    },
    links: {
      test: {
        domain: 'https://test-online.wiseup.com',
        firstAccess:
          'https://test-online.wiseup.com/first-access?token=${token}&mode=CONFIRMATION&origin=checkout',
        terms: 'https://test.wiseup.com/pt/online/termos-de-servico/',
        politics: 'https://wiseup.com/politica-de-privacidade/',
      },
      homolog: {
        domain: 'https://online.wiseup.com',
        firstAccess:
          'https://online.wiseup.com/first-access?token=${token}&mode=CONFIRMATION&origin=checkout',
        terms: 'https://wiseup.com/pt/online/termos-de-servico/',
        politics: 'https://wiseup.com/politica-de-privacidade/',
      },
      production: {
        domain: 'https://online.wiseup.com',
        firstAccess:
          'https://online.wiseup.com/first-access?token=${token}&mode=CONFIRMATION&origin=checkout',
        terms: 'https://wiseup.com/pt/online/termos-de-servico/',
        politics: 'https://wiseup.com/politica-de-privacidade/',
      },
    },
  },

  style: {
    form: {
      maxWidth: '485px',
    },
    assets: {
      logo: {
        file: 'logo-wup.svg',
        width: '153px',
      },
      favicon: 'favicon-wol.ico',
      loading: 'rolling-wp.svg',

      /**
       * @deprecated
       * Daqui para baixo este objeto está obsoleto
       * Foi necessário manter apenas para garantir a visualização
       * correta de fluxos antigos que utilizam este tema, como o fluxo da livelo...
       * Remover assim que virar os fluxos que ainda usam esses estilos
       */
      backgroundLoading: '#180a29e6',
    },
    backgroundColor: '#180a29',
    backgroundGradient: '#2C1D3E',
    ribbonColor: '#00eb84',
    invoiceDetails: {
      borderColor: '#180a29',
      divisorColor: '#180a29',
    },
    link: {
      color: '#00eb84',
      bgColor: '#271042',
    },
    pix: {
      backgroundColor: '#2a004f',
      backgroundColorLogo: '#fff',
      buttonColor: '#00eb84',
      backgroundBlockError: '#180a29cc',
      boxShadow: '#beb1c9',
    },
    text: {
      color: '#fff',
      subColor: '#62587D',
      descriptionColor: '#bcbcbc',
      fontFamily: "'Nunito', sans-serif",
      title: {
        fontSize: '20px',
        lineHeight: '25px',
      },
      subtitle: {
        fontSize: '14px',
        lineHeight: '25px',
      },
      body: {
        fontSize: '14px',
        lineHeight: '16px',
      },
    },
    fontFamily: "'Nunito', sans-serif",
    sellerBox: {
      backgroundColor: '#2C1D3E',
      color: '#fff',
      borderBottom: '1px solid #62587D',
    },
    buttons: {
      webkitAppearance: 'none',
      mozAppearance: 'none',
      appearance: 'none',
      webkitBorderRadius: '8px',
      khtmlBorderRadius: '8px',
      mozBorderRadius: '8px',
      msBorderRadius: '8px',
      oBorderRadius: '8px',
      borderRadius: '8px',
      backgroundColor: '#00eb84',
      fontSize: '14px',
      fontWeight: 'bold',
      textAlign: 'center',
      color: '#2a004f',
      height: '48px',
      display: 'block',
      border: 'none',
      fontFamily: "'Nunito', sans-serif",
      cursor: 'pointer',
      margin: '20px auto 50px',
      width: '100%',
      textTransform: 'none',
      lineHeight: '1.57',
      letterSpacing: '0',
      videoMaxWidth: '465px',
    },
    input: {
      width: '100%',
      border: 'none',
      borderBottom: 'none',
      borderRadius: '8px',
      backgroundColor: '#2C1D3E',
      fontSize: '14px',
      fontWeight: 'normal',
      boxShadow: 'none',
      color: '#fff',
      fontFamily: '"Nunito", sans-serif',
      transition: 'none',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '14px',
      paddingTop: '14px',
      padding: '14px 16px',
      height: '48px',
      boxSizing: 'border-box',
      active: {
        color: '#180a29',
      },
      code: {
        borderBottom: 'solid 1px #404040',
        height: 'auto !important',
      },
      valid: {
        borderBottom: 'none',
        border: 'none',
        outline: 'none',
      },
      invalid: {
        borderBottom: 'none',
        border: 'none',
        outline: '1px solid var(--feedback-color-error-pure)',
      },
      focus: {
        borderBottom: 'none',
        border: 'none',
        outline: '2px solid #62587d',
      },
      outlined: {
        borderBottom: 'none',
        border: 'none',
        outline: '2px solid #4F4965',
      },
      autofill: {
        border: 'none',
        webkitTextFillColor: '#fff',
        transition: 'background-color 5000s ease-in-out 0s',
        borderRadius: '8px',
      },
    },
    select: {
      width: '100%',
      position: 'relative',
      border: 'none',
      borderBottom: 'none',
      backgroundColor: '#2C1D3E',
      backgroundImage: 'url(/static/arrow-down.png)',
      backgroundPosition: 'right',
      backgroundRepeat: 'no-repeat',
      backgroundOrigin: 'content-box',
      fontSize: '14px',
      fontWeight: 'normal',
      boxShadow: 'none',
      color: '#fff',
      fontFamily: "'Nunito',sans-serif",
      transition: 'none',
      height: '48px',
      padding: '14px 16px',
      appearance: 'none',
      webkitAppearance: 'none',
      mozAppearance: 'none',
      borderRadius: '8px',
      webkitBorderRadius: '8px',
      khtmlBorderRadius: '8px',
      mozBorderRadius: '8px',
      msBorderRadius: '8px',
      outline: '2px solid #4f4965',
      focus: {
        borderBottom: 'none',
        border: 'none',
        outline: '2px solid #62587d',
      },
      placeholder: {
        fontFamily: "'Nunito', sans-serif",
        textTransform: 'none',
        color: '#898399',
      },
      autofill: {
        border: 'none',
        webkitTextFillColor: '#fff',
        webkitBoxShadow: 'none',
        transition: 'background-color .2s ease-in-out',
        borderRadius: '8px',
      },
      errorMsg: {
        fontSize: '13px',
        display: 'block',
        maxWidth: '375px',
        color: 'var(--feedback-color-error-pure)',
        margin: '0 0 -.6vh',
        padding: '8px 0 0',
        position: 'relative',
      },
    },
    fieldWrapper: {
      position: 'relative',
      paddingBottom: '16px',
      width: '100%',
      textAlign: 'left',
    },
    fieldLabel: {
      display: 'block',
      fontWeight: 700,
      position: 'relative',
      margin: '0 0 8px',
      marginBottom: '8px',
      color: '#ffffff',
      letterSpacing: 'normal',
      // transition: 'all 0.3s ease-in-out 0s', // temporario
      transition: 'none',
      fontSize: '12px',
      lineHeight: '18px',
      fontFamily: "'Nunito', sans-serif",
      zIndex: 9,
      active: {
        color: '#180a29',
      },
    },
    lead: {
      formTitle: {
        fontFamily: "'Nunito', sans-serif",
        fontWeight: 'normal',
        fontSize: '20px',
        textAlign: 'center',
        lineHeight: 'normal',
        color: '#ffffff',
        marginBottom: '30px',
      },
      formSubtitle: {
        fontFamily: "'Nunito', sans-serif",
        fontWeight: 'normal',
        fontSize: '20px',
        textAlign: 'center',
        color: '#ffffff',
      },
      checkboxPolitica: {
        boxCheckPolitica: {
          margin: '15px 0 35px 0',
        },
        textBoxPolitica: {
          color: '#BCBCBC',
          colorLink: '#00EC84',
        },
        checkmarkPolitica: {
          border: '2px solid #FFFFFF',
          backgroundColor: '#00EB84',
        },
      },
    },
    payment: {
      formTitle: {
        fontFamily: "'Nunito', sans-serif",
        fontWeight: 'normal',
        fontSize: '20px',
        textAlign: 'center',
        lineHeight: 'normal',
        color: '#ffffff',
        marginBottom: '30px',
      },
      formSubtitle: {
        fontFamily: "'Nunito', sans-serif",
        fontWeight: 'normal',
        fontSize: '20px',
        textAlign: 'center',
        color: '#ffffff',
      },
      formText: {
        fontFamily: "'Nunito', sans-serif",
        fontWeight: 'normal',
        fontSize: '14px',
        textAlign: 'center',
        color: '#9e9e9e',
        width: '100%',
        margin: '0 auto 24px',
      },
    },
    error: {
      formErrorMsg: {
        margin: '20px 0',
        fontSize: '14px',
        paddingTop: '2px',
        display: 'block',
        color: '#FF598A',
        textAlign: 'center',
      },
      errorMsg: {
        fontSize: '13px',
        display: 'block',
        maxWidth: '375px',
        color: 'var(--feedback-color-error-pure)',
        margin: '0 0 -.6vh',
        padding: '8px 0 0',
        position: 'relative',
      },
    },
    steps: {
      display: 'none',
      color: '#9E9E9E',
      checkColor: '#180a29',
      borderColor: '#00eb84',
      backgroundColor: '#2C1D3E',
      maxWidth: '465px',
    },
    success: {
      maxWidth: '400px',
      containerSuccess: {
        marginTop: '10px',
        width: '100%',
      },
      boxSuccess: {
        maxWidth: '300px',
        paddingLeft: '0',
        paddingRight: '0',
      },
      background: {
        paddingTop: '10px',
        display: 'block',
        image: 'success-back.svg',
        position: '50% 40%',
      },
      title: {
        fontSize: '32px',
        lineHeight: '50px',
        fontFamily: "'Nunito', sans-serif",
        width: 'auto',
        margin: '100px auto 0',
        fontWeight: 'bold',
      },
      text: {
        lineHeight: '18px',
        fontSize: '14px',
        color: '#fff',
        width: '275px',
        margin: '0 auto 60px',
        fontStyle: 'normal',
        fontWeight: '400',
        textAlign: 'center',
      },
      terms: {
        color: '#9E9E9E',
        fontSize: '12px',
        margin: '20px 0',
        display: 'none',
        width: '100%',
        link: {
          color: '#f16522',
          textDecoration: 'none',
        },
      },
      button: {
        width: '100%',
        textTransform: 'uppercase',
        letterSpacing: '0',
        fontSize: '14px',
      },
    },
    successBoleto: {
      maxWidth: '400px',
      background: {
        paddingTop: '10px',
        display: 'block',
        image: 'success-back.svg',
        position: '26px 16px',
      },
      title: {
        fontSize: '32px',
        lineHeight: '67px',
        width: 'auto',
        margin: '45px auto 0',
        fontWeight: '600',
      },
      text: {
        lineHeight: '18px',
        fontSize: '16px',
        color: '#fff',
        width: '275px',
        margin: '10px auto 20px',
        display: 'block',
        fontStyle: 'normal',
        fontWeight: '400',
        textAlign: 'center',
      },
      terms: {
        color: '#9E9E9E',
        fontSize: '12px',
        margin: '20px 0',
        display: 'none',
        width: '100%',
        link: {
          color: '#f16522',
          textDecoration: 'none',
        },
      },
    },
    successInvoice: {
      ribbonColor: '#00EB84',
      dropShadow: '#00eb8480',
    },
    paymentMethod: {
      float: 'none',
      width: '100%',
      border: '1px solid #00eb84',
      marginBottom: '30px',
      borderRadius: '20px',
      paddingBottom: '10px',
      boxTitle: {
        float: 'left',
        width: '100%',
        cursor: 'default',
        boxSelection: {
          display: 'none',
          float: 'left',
          width: '16%',
          textAlign: 'center',
          ballSelection: {
            display: 'inline-block',
            marginTop: '20px',
            width: '17px',
            height: '17px',
            border: '1px solid #00eb84',
            borderRadius: '100%',
          },
        },
        titlePaymentMethod: {
          float: 'left',
          width: '100%',
          title_1: {
            margin: '8px 0 0 0',
            fontFamily: "'Nunito', sans-serif",
            fontWeight: 'bold',
            textAlign: 'left',
            fontSize: '18px',
            color: '#fff',
            lineHeight: '25px',
          },
          title_2: {
            margin: '0 0 0 0',
            fontFamily: "'Nunito', sans-serif",
            fontWeight: 'bold',
            fontSize: '14px',
            color: '#00eb84',
            lineHeight: '25px',
          },
        },
      },
      contentPaymentMethod: {
        marginTop: '16px',
        float: 'none',
        width: '100%',
        paddingLeft: '0',
        paddingRight: '0',
        display: 'none',
        title_1: {
          margin: '4px 0 8px 10px',
          opacity: '0.5',
          color: '#fff',
          fontSize: '14px',
          textAlign: 'left',
          fontWeight: 'bold',
        },
      },
      active: {
        backgroundColor: 'none',
        border: '0',
        boxTitle: {
          boxSelection: {
            ballSelection: {
              background: '#00eb84',
            },
          },
        },
        contentPaymentMethod: {
          display: 'block',
        },
      },
    },
    parsing: {
      maxWidth: '286px',
      background: {
        paddingTop: '10px',
        display: 'block',
        image: 'success-back.svg',
        position: 'top',
      },
      title: {
        fontSize: '32px',
        lineHeight: '67px',
        width: 'auto',
        margin: '30px auto 0',
        fontWeight: '600',
      },
      text: {
        lineHeight: '18px',
        fontSize: '16px',
        color: '#fff',
        width: '275px',
        margin: '0 auto 60px',
        fontStyle: 'normal',
        fontWeight: '400',
        textAlign: 'center',
      },
    },
    terms: {
      color: '#ffffff',
      fontSize: '12px',
      margin: '3vh 0 5vh',
      display: 'block',
      width: '100%',
      textTransform: 'uppercase',
      textAlign: 'center',
      letterSpacing: '2px',
      link: {
        color: '#00eb84',
        textDecoration: 'none',
      },
    },
  },

  text: {
    sellerBox: 'Atendimento:',
    lead: {
      text: 'Informe seus dados',
      description:
        'Complete suas informações para prosseguir para o pagamento.',
      button: 'Continuar',

      labels: {
        name: 'Nome Completo',
        email: 'E-mail',
        phone: 'Celular',
      },
      placeholder: {
        name: 'Ex: João Silva',
        email: 'Ex: seuemail@gmail.com',
        phone: 'Ex: 41999999999',
      },
    },
    payment: {
      title: 'Falta pouco pra você começar a estudar',
      description:
        'Assim que você confirmar esta etapa, sua assinatura anual será ativada e você terá acesso a todo conteúdo do <b>Wise Up Online</b>.',
      button: 'Confirmar pagamento',
      upsell50: {
        title: '50% de desconto<br/><br/>',
        subtitle:
          'para você, aluno do meuSucesso.com, estudar no Wise Up Online<br/><br/>',
        description:
          'Aproveite o voucher exclusivo para aprender com os maiores players do mercado, selecione seu plano anual e comece agora mesmo.',
      },
      labels: {
        installments: 'Selecione o número de parcelas',
        cardCompany: 'Selecione sua bandeira',
        cardName: 'Nome do titular',
        cardNumber: 'Número do cartão',
        cardExpireDate: 'Data de validade',
        cardCVV: 'CVV',
        cpf: 'CPF para emissão da nota fiscal',
      },
      placeholder: {
        cardCompany: 'Selecione',
        cardName: 'Ex: João Silva',
        cardNumber: '**** **** **** ****',
        cardExpireDate: 'MM/AA',
        cardCVV: 'xxx',
      },
    },
    success: {
      default: {
        title:
          'Seja bem-vindo(a) ao <b class="no-line-break-word">{MAIN_PRODUCT_NAME}</b>',
        icon: 'check_circle',
        button: {
          text: 'Saiba mais e aproveite uma condição exclusiva',
        },
      },
      bankslip: {
        title: 'Quase lá!',
        subtitle:
          'Seu boleto já está disponível para pagamento. Você pode acessá-lo abaixo. <br /> <br /> Em até 2 dias após a confirmação do pagamento, você receberá um e-mail para finalizar seu cadastro.<br /><br /><br />',
        icon: 'smiley',
        button: {
          text: 'Ver boleto',
        },
      },
      upsell: {
        title: 'Assinatura realizada com sucesso!',
        subtitle: 'Aproveite essa oportunidade agora!<br /><br />',
        icon: 'check_circle',
        button: {
          text: 'Sim, quero um desconto especial para ter aulas ao vivo',
        },
        secondaryButton: {
          text: 'Não, muito obrigado',
        },
      },
      upsellWithWhatsapp: {
        title: 'Assinatura realizada com sucesso!',
        subtitle: 'Aproveite essa oportunidade agora!<br /><br />',
        icon: 'check_circle',
        button: {
          text: 'Saiba mais e aproveite uma condição exclusiva',
          icon: 'whatsapp_logo',
        },
        secondaryButton: {
          text: 'Não, muito obrigado',
        },
      },
    },
  },
} as const;
