import * as Tokens from '@wisereducacao-npm/design-system-tokens/dist/esm/ms/dark/dark';
import {
    border,
    font as globalFontStyles,
    lineHeight,
    opacity,
    spacing,
} from '../global';

const color = {
  primary: {
    dark: Tokens.ColorPrimaryDark,
    light: Tokens.ColorPrimaryLight,
    medium: Tokens.ColorPrimaryMedium,
    pure: Tokens.ColorPrimaryPure,
  },
  secondary: {
    dark: Tokens.ColorSecondaryDark,
    light: Tokens.ColorSecondaryLight,
    medium: Tokens.ColorSecondaryMedium,
    pure: Tokens.ColorSecondaryPure,
  },
  neutral: {
    button: Tokens.NeutralColorButton,
    highContrast: Tokens.NeutralColorHighContrast,
    lowContrast: Tokens.NeutralColorLowContrast,
    mediumContrast: Tokens.NeutralColorMediumContrast,
    pure: Tokens.NeutralColorPure,
  },
  feedback: {
    success: {
      highContrast: Tokens.FeedbackColorSuccessHighContrast,
      lowContrast: Tokens.FeedbackColorSuccessLowContrast,
      mediumContrast: Tokens.FeedbackColorSuccessMediumContrast,
      pure: Tokens.FeedbackColorSuccessPure,
    },
    alert: {
      highContrast: Tokens.FeedbackColorAlertHighContrast,
      lowContrast: Tokens.FeedbackColorAlertLowContrast,
      mediumContrast: Tokens.FeedbackColorAlertMediumContrast,
      pure: Tokens.FeedbackColorAlertPure,
    },
    error: {
      highContrast: Tokens.FeedbackColorErrorHighContrast,
      lowContrast: Tokens.FeedbackColorErrorLowContrast,
      mediumContrast: Tokens.FeedbackColorErrorMediumContrast,
      pure: Tokens.FeedbackColorErrorPure,
    },
    button: Tokens.FeedbackColorButton,
  },
};

const font = {
  ...globalFontStyles,
  family: {
    primary: Tokens.FontFamilyPrimary,
  },
};

export const meusucessoMZ = {
  color,
  spacing,
  font,
  lineHeight,
  border,
  opacity,
  data: {
    business: 'meusucesso',
    dsProductId: 'ms',
    isTermsAndPoliticsAvailable: true,

    googleTagManagerId: 'GTM-P6WLD44',
    lang: 'pt-MZ',
    faqLink: 'https://meusucesso.com/faq',
    product: {
      bffId: 'meusucesso',
      name: 'Meu Sucesso',
      plans: {
        anualProductId: '2ef0bf87-8545-11e8-a05a-06774bcfafda',
        monthlyProductId: 'ff4675b0-1a91-46dd-aae0-b3bbd1761268',
      },
      price: {
        productCyclePrice: 1560,
        productFullPrice: 18720,
        productStandardCycle: 1560,
        productStandardPrice: 18720,
      },
    },
    payment: {
      methods: {
        creditcard: {
          active: 'only',
          name: 'Cartão de Crédito',
          planId: {
            test: '2ef0bf87-8545-11e8-a05a-06774bcfafda',
            production: '2ef0bf87-8545-11e8-a05a-06774bcfafda',
          },
        },
        debitcard: {
          active: false,
          name: 'Cartão de Débito',
          planId: {
            test: '',
            production: '',
          },
        },
        boleto: {
          active: false,
          name: 'Boleto Bancário',
          planId: {
            test: '99d3f7f1-500f-45d2-adb1-35de2d25fe54',
            production: '99d3f7f1-500f-45d2-adb1-35de2d25fe54',
          },
        },
      },
      hasInstallments: false,
      installments: [],
      cardCompanies: [
        { value: 'american_express', name: 'Amex' },
        { value: 'visa', name: 'Visa' },
        { value: 'mastercard', name: 'Mastercard' },
        { value: 'elo', name: 'Elo' },
        { value: 'diners', name: 'Diners' },
      ],
      states: [
        { value: 'AC', name: 'AC' },
        { value: 'AL', name: 'AL' },
        { value: 'AP', name: 'AP' },
        { value: 'AM', name: 'AM' },
        { value: 'BA', name: 'BA' },
        { value: 'CE', name: 'CE' },
        { value: 'DF', name: 'DF' },
        { value: 'ES', name: 'ES' },
        { value: 'GO', name: 'GO' },
        { value: 'MA', name: 'MA' },
        { value: 'MT', name: 'MT' },
        { value: 'MS', name: 'MS' },
        { value: 'MG', name: 'MG' },
        { value: 'PA', name: 'PA' },
        { value: 'PB', name: 'PB' },
        { value: 'PR', name: 'PR' },
        { value: 'PE', name: 'PE' },
        { value: 'PI', name: 'PI' },
        { value: 'RJ', name: 'RJ' },
        { value: 'RN', name: 'RN' },
        { value: 'RS', name: 'RS' },
        { value: 'RO', name: 'RO' },
        { value: 'RR', name: 'RR' },
        { value: 'SC', name: 'SC' },
        { value: 'SP', name: 'SP' },
        { value: 'SE', name: 'SE' },
        { value: 'TO', name: 'TO' },
      ],
    },
    links: {
      test: {
        domain: 'https://test-v2.meusucesso.com',
        firstAccess:
          'https://test-v2.meusucesso.com/primeiro-acesso?source=email&token=${token}',
        terms: 'https://meusucesso.com/mz/termos-de-servico/',
        politics: 'https://meusucesso.com/mz/politica-de-privacidade/',
      },
      homolog: {
        domain: 'https://v2.meusucesso.com',
        firstAccess:
          'https://v2.meusucesso.com/primeiro-acesso?source=email&token=${token}',
        politics: 'https://meusucesso.com/mz/politica-de-privacidade/',
        terms: 'https://meusucesso.com/mz/termos-de-servico/',
      },
      production: {
        domain: 'https://v2.meusucesso.com',
        firstAccess:
          'https://v2.meusucesso.com/primeiro-acesso?source=email&token=${token}',
        politics: 'https://meusucesso.com/mz/politica-de-privacidade/',
        terms: 'https://meusucesso.com/mz/termos-de-servico/',
      },
    },
  },

  style: {
    form: {
      maxWidth: '465px',
    },
    ribbonColor: '#565555',
    backgroundColor: '#000',
    backgroundGradient: '#272727',
    invoiceDetails: {
      borderColor: '#fff',
      divisorColor: '#777777',
    },
    link: {
      color: '#EC5012',
      bgColor: '#2D2D2D',
    },
    text: {
      color: '#fff',
      subColor: '#868585',
      descriptionColor: '#bcbcbc',
      fontFamily: "'Muli', sans-serif",
      title: {
        fontSize: '20px',
        lineHeight: '25px',
      },
      subtitle: {
        fontSize: '14px',
        lineHeight: '25px',
      },
      body: {
        fontSize: '14px',
        lineHeight: '16px',
      },
    },
    fontFamily: "'Muli', sans-serif",
    sellerBox: {
      backgroundColor: '#272727',
      color: '#ffffff',
      borderBottom: '1px solid #565555',
    },
    buttons: {
      webkitAppearance: 'none',
      mozAppearance: 'none',
      appearance: 'none',
      webkitBorderRadius: '8px',
      khtmlBorderRadius: '8px',
      mozBorderRadius: '8px',
      msBorderRadius: '8px',
      oBorderRadius: '8px',
      borderRadius: '8px',
      backgroundColor: '#EC5012',
      fontSize: '14px',
      fontWeight: 'bold',
      textAlign: 'center',
      color: '#ffffff',
      height: '48px',
      display: 'block',
      fontFamily: '"Muli", sans-serif',
      border: 'none',
      cursor: 'pointer',
      margin: '20px auto 50px',
      width: '100%',
      textTransform: 'none',
      lineHeight: '1.57',
      letterSpacing: '0',
      videoMaxWidth: '465px',
    },
    input: {
      width: '100%',
      border: 'none',
      borderBottom: 'none',
      borderRadius: '8px',
      backgroundColor: '#272727',
      fontSize: '14px',
      fontWeight: 'normal',
      boxShadow: 'none',
      color: '#fff',
      fontFamily: '"Muli", sans-serif',
      transition: 'none',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '14px',
      paddingTop: '14px',
      padding: '14px 16px',
      height: '48px',
      boxSizing: 'border-box',
      code: {
        borderBottom: 'none',
        height: '63px !important',
      },
      active: {
        color: '#fff',
      },
      valid: {
        borderBottom: 'none',
        border: 'none',
        outline: 'none',
      },
      invalid: {
        borderBottom: 'none',
        border: 'none',
        outline: '1px solid var(--feedback-color-error-pure)',
      },
      focus: {
        borderRadius: '8px',
        borderBottom: 'none',
        border: 'none',
        outline: '2px solid #868585',
      },
      outlined: {
        borderBottom: 'none',
        border: 'none',
        outline: '2px solid #8685858a',
      },
      autofill: {
        border: 'none',
        borderRadius: '8px',
        webkitTextFillColor: '#fff!important',
        backgroundColor: 'none',
        webkitBoxShadow: 'none',
        transition: 'background-color 5000s ease-in-out 0s',
      },
    },
    select: {
      width: '100%',
      position: 'relative',
      border: 'none',
      borderBottom: 'none',
      backgroundColor: '#272727',
      backgroundImage: 'url(/static/arrow-down.png)',
      backgroundPosition: 'right',
      backgroundRepeat: 'no-repeat',
      backgroundOrigin: 'content-box',
      fontSize: '14px',
      fontWeight: 'normal',
      boxShadow: 'none',
      color: '#fff',
      fontFamily: "'Muli',sans-serif",
      transition: 'none',
      padding: '14px 16px',
      appearance: 'none',
      webkitAppearance: 'none',
      mozAppearance: 'none',
      borderRadius: '8px',
      webkitBorderRadius: '8px',
      khtmlBorderRadius: '8px',
      mozBorderRadius: '8px',
      msBorderRadius: '8px',
      outline: '2px solid #8685858a',
      focus: {
        borderBottom: 'none',
        border: 'none',
        outline: '1px solid #868585',
      },
      outlined: {
        borderBottom: 'none',
        border: 'none',
        outline: '2px solid #8685858a',
      },
      placeholder: {
        fontFamily: "'Muli', sans-serif",
        textTransform: 'none',
        color: '#BCBCBC',
      },
      autofill: {
        border: 'none',
        borderBottom: 'noene',
        backgroundColor: '#272727!important',
        transition: 'background-color 5000s ease-in-out 0s',
        webkitTextFillColor: '#fff!important',
        webkitBoxShadow: 'none',
        boxShadow: 'none',
        borderRadius: '8px',
      },
      errorMsg: {
        fontSize: '13px',
        display: 'block',
        maxWidth: '375px',
        color: '#cb0000',
        margin: '0 0 -.6vh',
        padding: '8px 0 0',
        position: 'relative',
      },
    },
    fieldWrapper: {
      position: 'relative',
      paddingBottom: '16px',
      width: '100%',
      textAlign: 'left',
    },
    fieldLabel: {
      display: 'block',
      fontWeight: 700,
      position: 'relative',
      margin: '0 0 8px',
      marginBottom: '8px',
      color: '#fff',
      letterSpacing: 'normal',
      transition: 'none',
      fontSize: '12px',
      lineHeight: '18px',
      fontFamily: "'Muli', sans-serif",
      zIndex: 9,
      active: {
        color: '#EC5012',
      },
    },
    lead: {
      formTitle: {
        fontFamily: "'Merriweather', sans-serif",
        fontWeight: 'bold',
        fontSize: '20px',
        textAlign: 'center',
        lineHeight: 'normal',
        color: '#ffffff',
        marginBottom: '30px',
      },
      formSubtitle: {
        fontFamily: "'Merriweather', sans-serif",
        fontWeight: 'bold',
        fontSize: '20px',
        textAlign: 'center',
        color: '#bcbcbc',
      },
      checkboxPolitica: {
        boxCheckPolitica: {
          margin: '15px 0 35px 0',
        },
        textBoxPolitica: {
          color: '#FFFFFF',
          colorLink: '#EC5012',
        },
        checkmarkPolitica: {
          border: '2px solid #FFFFFF',
          backgroundColor: '#EC5012',
        },
      },
    },
    payment: {
      formTitle: {
        fontFamily: "'Merriweather', sans-serif",
        fontWeight: 'normal',
        fontSize: '20px',
        textAlign: 'center',
        lineHeight: 'normal',
        color: '#ffffff',
        marginBottom: '30px',
      },
      formSubtitle: {
        fontFamily: '"Muli", "Muli", sans-serif',
        fontWeight: 'normal',
        fontSize: '20px',
        textAlign: 'center',
        color: '#bcbcbc',
      },
      formText: {
        fontFamily: "'Muli', sans-serif",
        fontWeight: 'normal',
        fontSize: '14px',
        textAlign: 'center',
        color: '#bcbcbc',
        width: '100%',
        margin: '0 auto 24px',
      },
    },
    error: {
      formErrorMsg: {
        margin: '20px 0',
        fontSize: '14px',
        paddingTop: '2px',
        display: 'block',
        color: '#FF598A',
        textAlign: 'center',
      },
      errorMsg: {
        fontSize: '13px',
        display: 'block',
        maxWidth: '375px',
        color: 'var(--feedback-color-error-pure)',
        margin: '0 0 -.6vh',
        padding: '8px 0 0',
        position: 'relative',
      },
    },
    steps: {
      display: 'none',
      color: '#9E9E9E',
      borderColor: '#EC5012',
      backgroundColor: '#313030',
      maxWidth: '465px',
    },
    success: {
      maxWidth: '465px',
      containerSuccess: {
        marginTop: '10px',
        width: '100%',
      },
      boxSuccess: {
        maxWidth: '300px',
        paddingLeft: '0',
        paddingRight: '0',
      },
      background: {
        paddingTop: '10px',
        display: 'block',
        image: 'success-back.svg',
        position: '50% 40%',
      },
      title: {
        fontSize: '32px',
        lineHeight: '50px',
        width: 'auto',
        fontFamily: '"Muli", sans-serif',
        margin: '100px auto 0',
        fontWeight: 'bold',
      },
      text: {
        lineHeight: '18px',
        fontSize: '14px',
        color: '#fff',
        width: '275px',
        margin: '0 auto 60px',
        fontStyle: 'normal',
        fontWeight: '400',
        textAlign: 'center',
      },
      terms: {
        color: '#9E9E9E',
        fontSize: '12px',
        margin: '20px 0',
        display: 'none',
        width: '100%',
        link: {
          color: '#EC5012',
          textDecoration: 'none',
        },
      },
      button: {
        width: '100%',
        textTransform: 'uppercase',
        letterSpacing: '0',
        fontSize: '14px',
      },
    },
    successBoleto: {
      maxWidth: '275px',
      background: {
        paddingTop: '',
        display: 'none',
        image: '',
        position: '',
      },
      title: {
        fontSize: '60px',
        lineHeight: '67px',
        width: '275px',
        margin: '20px auto 30px',
        fontWeight: '600',
      },
      text: {
        lineHeight: '18px',
        fontSize: '14px',
        color: '#bcbc',
        width: '300px',
        margin: '10px auto 20px',
        display: 'block',
        fontStyle: 'normal',
        fontWeight: '400',
        textAlign: 'center',
      },
      terms: {
        color: '#9E9E9E',
        fontSize: '12px',
        margin: '20px 0',
        display: 'none',
        width: '100%',
        link: {
          color: '#EC5012',
          textDecoration: 'none',
        },
      },
    },
    paymentMethod: {
      float: 'left',
      width: '100%',
      border: '1px solid #EC5012',
      marginBottom: '30px',
      borderRadius: '20px',
      paddingBottom: '10px',
      boxTitle: {
        float: 'left',
        width: '100%',
        cursor: 'pointer',
        boxSelection: {
          float: 'left',
          width: '16%',
          textAlign: 'center',
          ballSelection: {
            display: 'inline-block',
            marginTop: '20px',
            width: '17px',
            height: '17px',
            border: '1px solid #EC5012',
            borderRadius: '100%',
          },
        },
        titlePaymentMethod: {
          float: 'left',
          width: '84%',

          title_1: {
            margin: '16px 0 0 0',
            fontFamily: "'Muli', sans-serif",
            fontWeight: 'bold',
            textAlign: 'left',
            fontSize: '18px',
            color: '#fff',
            lineHeight: '25px',
          },
          title_2: {
            margin: '0 0 0 0',
            fontFamily: "'Muli', sans-serif",
            fontWeight: 'bold',
            fontSize: '14px',
            color: '#00eb84',
            lineHeight: '25px',
          },
        },
      },
      contentPaymentMethod: {
        float: 'left',
        width: '81%',
        paddingLeft: '14%',
        paddingRight: '5%',
        display: 'none',
        title_1: {
          margin: '4px 0 8px 10px',
          opacity: '0.5',
          color: '#fff',
          fontSize: '14px',
          textAlign: 'left',
          fontWeight: 'bold',
        },
      },
      active: {
        backgroundColor: '#2a004f',
        border: '0',

        boxTitle: {
          boxSelection: {
            ballSelection: {
              background: '#00eb84',
            },
          },
        },
        contentPaymentMethod: {
          display: 'block',
        },
      },
    },
    parsing: {
      maxWidth: '286px',
      background: {
        paddingTop: '10px',
        display: 'block',
        image: '',
        position: 'top',
      },
      title: {
        fontSize: '32px',
        lineHeight: '67px',
        width: 'auto',
        margin: '30px auto 0',
        fontWeight: '600',
      },
      text: {
        lineHeight: '18px',
        fontSize: '16px',
        color: '#fff',
        width: '275px',
        margin: '0 auto 60px',
        fontStyle: 'normal',
        fontWeight: '400',
        textAlign: 'center',
      },
    },
    terms: {
      color: '#fff',
      fontSize: '12px',
      margin: '3vh 0 5vh',
      display: 'block',
      width: '100%',
      textAlign: 'center',
      letterSpacing: '2px',
      link: {
        color: '#EC5012',
        textDecoration: 'none',
      },
    },
    termsB: {
      color: '#fff',
      fontSize: '12px',
      margin: '3vh 0 5vh',
      display: 'block',
      width: '100%',
      textAlign: 'center',
      letterSpacing: '2px',
      link: {
        terms: 'https://meusucesso.com/termos-de-servico-b',
        politics: 'https://meusucesso.com/politica-de-privacidade/',
        color: '#EC5012',
        textDecoration: 'none',
      },
    },
    assets: {
      logo: {
        file: 'logo-ms.svg',
        width: '153px',
      },
      favicon: 'favicon-meusucesso.ico',
      loading: 'rolling-ms.svg',
      backgroundLoading: '#000000b5',
    },
  },

  text: {
    sellerBox: 'Atendimento:',
    error: {
      general:
        'Ocorreu um erro inesperado, por gentileza tente novamente mais tarde',
      payment:
        'Não conseguimos processar a transação. Faça uma nova tentativa.',
    },
    lead: {
      title: 'Cadastro',
      text: 'Para começarmos, preencha os seus dados pessoais',
      button: 'Continuar',
      toco: {
        title: 'Cadastro',
        text: 'Preencha seus dados para criar sua conta.',
      },
      labels: {
        name: 'Nome Completo',
        email: 'E-mail',
        phone: 'Telemóvel',
      },
      placeholder: {
        name: 'Ex: João Silva',
        email: 'Ex: nome@mail.com',
        phone: 'Ex: 84 123 1234',
      },
    },
    // Fluxo normal, aonde o cliente nunca fez um cadastro. - route: payment
    trial: {
      title:
        'Falta pouco para liberar seus 7 dias grátis. Informe o seu cartão para ter seu acesso.',
      text: 'Sua assinatura anual será ativada automaticamente após este período.',
      pretext:
        'Sua assinatura será ativada automaticamente após este período, com a cobrança em ',
      pretextBlackFriday:
        'Sua assinatura será ativada automaticamente após este período.<br>',
      textBlackfriday:
        'Aproveite o voucher de R$120,00 da Black Friday e ative a sua assinatura, de 12 parcelas de R$95,00 por 12 parcelas de R$85,00.<br>',
      price: '12x de R$95,00',
      postext:
        '<br>Caso não queira se tornar aluno, antes do fim dos 7 dias grátis faça sua solicitação em nosso site.',
    },
    // Fluxo de texto, aonde o cliente já passou pelo período de teste. - route: payment
    payment: {
      bankslip: {
        title: '',
        description: '',
      },
      title: 'Seu período de 7 dias grátis já foi utilizado.',
      text: 'Para ter acesso completo ao meuSucesso.com, informe os dados do seu cartão.',
      pretext:
        'Para ter acesso completo ao meuSucesso.com, informe os dados do seu cartão. Será cobrado ',
      pretextBlackFriday:
        'Sua assinatura será ativada automaticamente após este período.<br>',
      textBlackfriday:
        'Aproveite o voucher de R$120,00 da Black Friday e ative a sua assinatura, de 12 parcelas de R$95,00 por 12 parcelas de R$85,00.<br>',
      price: '12x de R$95,00',
      postext: ' - Plano anual.',
      button: 'Finalizar',
      buttonCardStep: 'Continuar',
      payWith: 'Pagar com ',
      address: 'Endereço de Cobrança',
      labels: {
        cardCompany: 'Selecione sua bandeira',
        cardName: 'Nome do titular',
        cardNumber: 'Número do cartão',
        cardExpireDate: 'Data de validade',
        cardCVV: 'CVV',
        postalCode: 'CEP',
        addressLine1: 'Rua',
        addressLine2: 'Número',
        addressLine3: 'Complemento',
        city: 'Cidade',
        state: 'Estado',
        country: 'País',
      },
      placeholder: {
        cardCompany: 'Selecione',
        cardName: 'Ex: João Silva',
        cardNumber: '**** **** **** ****',
        cardExpireDate: 'MM/AA',
        cardCVV: 'xxx',
        postalCode: 'xxxxx xxxx',
        addressLine1: 'xxxxx',
        addressLine2: 'xxxxx',
        addressLine3: 'xxxxx',
        city: 'Cidade',
        state: 'Estado',
        country: 'País',
      },
      errorMessage: {
        cardCompany: 'Selecione a bandeira do cartão',
      },
    },
    blackFriday: {
      title:
        'Falta pouco para liberar seus 7 dias grátis. Informe o seu cartão de crédito para ter seu acesso.',
      pretext: '',
      text: 'Aproveite o voucher de R$120,00 da Black Friday. Após seu período grátis, a cobrança será automática: de <i class="old-price">12 parcelas de R$95,00</i> por 12 parcelas de R$85,00.<br />',
      price: '12x de R$95,00',
      postext:
        '<br>Caso não queira se tornar aluno, antes do fim dos 7 dias grátis faça sua solicitação em nosso site.',
      payment:
        'Aproveite o voucher de R$120,00 da Black Friday e ative a sua assinatura, de <i class="old-price">12 parcelas de R$95,00</i> por 12 parcelas de R$85,00.',
    },
    partner40: {
      title: 'Falta pouco pra você começar a estudar.',
      text: 'Para ter acesso completo ao meuSucesso.com, informe os dados do seu cartão. Será cobrado <strong>12x de R$95,00</strong>',
      pretext:
        'Para ter acesso ao meuSucesso.com, informe os dados do seu cartão de crédito e ative a sua assinatura anual:<br />de ',
      price: '12x de R$95,00',
      postext: '<b>por 12 parcelas de R$61,00.</b>',
    },
    trialUsed: {
      title: 'Falta pouco pra você começar a estudar.',
      text: 'Informe o seu cartão de crédito para ativar sua assinatura anual, em <strong>12 parcelas de R$95,00</strong>',
      pretext:
        'Informe o seu cartão de crédito para ativar sua assinatura anual, em ',
      price: '12x de R$95,00',
      postext: '',
    },
    anualOnly: {
      title:
        'Falta pouco para liberar seus 7 dias grátis. Informe o seu cartão para ter seu acesso.',
      text: 'Sua assinatura anual será ativada automaticamente após este período, com a cobrança em <strong> 12x de R$95,00.</strong>',
      pretext:
        'Sua assinatura será ativada automaticamente após este período.<br>A cobrança será de ',
      price: '12x de R$95,00',
      postext:
        '<br>Caso não queira se tornar aluno, antes do fim dos 7 dias grátis faça sua solicitação em nosso site e seu cancelamento será realizado automaticamente.',
    },
    success: {
      title: 'Seja bem-vindo(a) ao meuSucesso.com',
      text: 'O seu acesso anual já está disponível! Clique no botão abaixo e continue para seu primeiro acesso.',
      button: 'Acessar o meuSucesso',
    },
    successBoleto: {
      title: 'Quase lá',
      text1:
        'Seu boleto será registrado no banco e estará disponível para pagamento em até 2 horas. Você pode acessá-lo abaixo.',
      text2:
        'Em até 2 dias após a confirmação de pagamento, seu acesso será enviado ao seu e-mail.',
      button: 'Ver boleto',
    },
  },
} as const;
