import ReturnFirstCapital from 'utils/returnFirstCapital';
import { parsePhoneNumber } from 'libphonenumber-js';
import cookie from 'cookie';
import jsCookie from 'js-cookie';
import { formatToBrazilianCurrency } from './formatCurrency';
import * as validate from 'utils/inputValidate';
import dayjs from 'dayjs';

export const jsonParse = (value) => {
  try {
    return JSON.parse(value);
  } catch (error) {
    console.error(`error on parsing value: ${value}:`, error);
    return null;
  }
};

export const jsonStringify = (value) => {
  try {
    return JSON.stringify(value);
  } catch (error) {
    console.error(`error on stringifying value: ${value}:`, error);
    return '';
  }
};

export const getLiveloPaymentOptiosInfos = (option) => {
  const getTitle = () => {
    const cashTitle = `R$${formatToBrazilianCurrency(option?.amountCash)}`;
    const pointsTitle = `${formatToBrazilianCurrency(
      option?.amountPoints,
      false
    )} pontos`;
    const cashPointsTitle = `${pointsTitle} + ${cashTitle}`;

    if (option?.type === 'cash') {
      return cashTitle;
    }
    if (option?.type === 'points') {
      return pointsTitle;
    }

    return cashPointsTitle;
  };

  const getDescription = () => {
    const cashDescription = 'Cartão de crédito';
    const liveloDescription = 'Pontos Livelo';
    const cashLiveloDescription = `${liveloDescription} + ${cashDescription}`;

    if (option?.type === 'cash') {
      return cashDescription;
    }
    if (option?.type === 'points') {
      return liveloDescription;
    }

    return cashLiveloDescription;
  };

  const title = getTitle();
  const description = getDescription();

  return {
    title,
    description,
  };
};

export const formatRedirectUrl = (originalUrl, redirectPath) => {
  const splittedUrl = originalUrl?.split('?') || [];
  const urlParams = splittedUrl?.length > 1 && splittedUrl[1];
  const route = splittedUrl[0] || '/';

  switch (route) {
    case '/livelo':
      return splittedUrl?.length > 1
        ? `${redirectPath}?${urlParams}&route=livelo`
        : `${redirectPath}?route=livelo`;

    default:
      return splittedUrl?.length > 1
        ? `${redirectPath}?${urlParams}`
        : redirectPath;
  }
};

export const isTestMode = (testQuery = '') => {
  return testQuery === 'on';
};

export const urlsWTP = (url) => {
  return [
    '/upsell-presentation',
    '/upsell-payment',
    '/subscription-welcome',
    '/multiprofile-upsell-presentation',
  ].includes(url);
};

const convertStringToDate = ({ date, inFormat = 'DD/MM/YYYY' }) => {
  return dayjs(date).format(inFormat);
};

export const formatDate = ({
  date,
  inFormat = 'DD/MM/YYYY',
  outFormat = 'DD/MM/YYYY',
}) => {
  const converteToDate = date && convertStringToDate({ date, inFormat });
  const formatDateString =
    converteToDate && dayjs(converteToDate).format(outFormat);

  return formatDateString;
};

export function errorsMessage(formik, nameField, object = null) {
  if (object) {
    if (
      !!formik.errors[object] &&
      !!formik.errors[object][nameField] &&
      !!formik.touched[object] &&
      !!formik.touched[object][nameField]
    ) {
      return formik.errors[object][nameField] &&
        formik.touched[object][nameField]
        ? formik.errors[object][nameField]
        : '';
    }
  }

  return formik.errors[nameField] && formik.touched[nameField]
    ? formik.errors[nameField]
    : '';
}

export const formatWspLiveLink = (environment) => {
  const links = {
    test: 'https://test-online.wiseup.com/login?lang=pt&redirect=https://test-checkout.wiseup.com/upsell-presentation?source=plataforma&seller=performance&voucherLive=partner50&referer=crossell',
    homolog: 'https://wup.onl/voucher-live',
    production: 'https://wup.onl/voucher-live',
  };

  return environment ? links[environment] : links.production;
};

export const normalizePhone = (phone, regionCode = 'BR') => {
  let number = '';

  try {
    number = parsePhoneNumber(phone, regionCode);

    return {
      value: number?.number,
      error: false,
    };
  } catch (error) {
    console.log('normalizePhone error: ', error);

    return {
      value: phone,
      error: true,
    };
  }
};

// app constants
export const urlsWithBackButton = [
  '/upsell-payment',
  '/payment',
  '/video',
  '/payment/upsell',
  '/presentation',
  '/order-bump-presentation',
  '/select-school',
];
export const loadingUpsellUrls = [
  '/upsell-payment',
  '/upsell-presentation',
  '/subscription-welcome',
  '/multiprofile-upsell-presentation',
];
export const urlsWithoutSellerBox = ['/payment-verification'];
export const urlsWithoutLogo = [
  '/upsell-presentation',
  '/upsell-payment',
  '/subscription-welcome',
  '/livelo/cpf',
  '/livelo/payment-method',
  '/livelo/payment',
  '/livelo/error',
  '/livelo/select-device',
  '/livelo/code',
  '/payment-verification',
  '/parsing',
  '/multiprofile-upsell-presentation',
  '/success-invoice',
];

export const getRawQuery = (pageProps) => {
  let rawQuery;
  if (pageProps?.query) {
    const lastItem = Object.keys(pageProps?.query)?.pop();
    rawQuery = `?`;

    for (const key in pageProps?.query) {
      const query = pageProps?.query?.[key];

      if (query && query.toLowerCase().startsWith('query_param')) {
        delete pageProps?.query?.[key];
      } else if (!query) {
        delete pageProps?.query?.[key];
      } else {
        rawQuery += `${key}=${pageProps?.query?.[key]}`;
        if (lastItem !== key) rawQuery += '&';
      }
    }

    if (!!pageProps?.query?.partner && !pageProps?.query?.seller) {
      pageProps.query.seller = `franchising`;
      rawQuery !== `?`
        ? (rawQuery += `&seller=franchising`)
        : (rawQuery += `seller=franchising`);
    } else if (!pageProps?.query?.source) {
      rawQuery !== `?`
        ? !pageProps?.query?.seller
          ? (rawQuery += `&source=direto&seller=performance`)
          : (rawQuery += `&source=direto`)
        : !pageProps?.query?.seller
          ? (rawQuery += `source=direto&seller=performance`)
          : (rawQuery += `source=direto`);
    }
  } else rawQuery = '?source=direto&seller=performance';

  return rawQuery;
};

export const getArea = (pageProps) => {
  const areaByLangQuery = {
    'PT-MZ': 'MZ',
  };

  return areaByLangQuery[pageProps?.query?.lang?.toUpperCase()] || 'BR';
};

export function getEnvironment(subdomain) {
  if (
    (subdomain?.toUpperCase().indexOf('TEST') > -1 ||
      subdomain?.toUpperCase().indexOf('DEV') > -1 ||
      subdomain?.toUpperCase().indexOf('LOCALHOST')) > -1
  ) {
    return 'test';
  }
  if (process.env.APPLICATION_ENV === 'homolog') {
    return 'homolog';
  }
  return 'production';
}

export function getProductByDomainOrProductSlug({ host, query }) {
  const splittedHost = host?.toUpperCase().split('.');
  const productFromDomain = splittedHost?.length > 0 ? splittedHost[1] : '';

  const queryProductSlug = query?.['product-slug']?.toUpperCase();

  const isLocalhost = host?.includes('localhost');

  if (isLocalhost) {
    /**
     * Essa lógica é necessária somente para o ambiente local para o produto eumilitar,
     * pois, em ambiente local, o produto e tema não é setado pelo domínio, mas sim
     * pela query string product-slug ou product, respectivamente
     */
    const isEmProduct =
      queryProductSlug?.toLowerCase()?.includes('eumilitar') ||
      query?.product?.toUpperCase() === 'EM';
    if (isEmProduct) {
      return 'EM';
    }

    const isConquerProduct = query?.product?.toUpperCase() === 'ESCOLACONQUER';
    if (isConquerProduct) {
      return 'ESCOLACONQUER';
    }

    const isAprovatotalProduct =
      queryProductSlug?.toLowerCase()?.includes('aprovatotal') ||
      query?.product?.toUpperCase() === 'APROVATOTAL';
    if (isAprovatotalProduct) {
      return 'APROVATOTAL';
    }

    const isWiseupProduct =
      queryProductSlug?.toLowerCase() === 'wiseup-exec' ||
      query?.product?.toUpperCase() === 'EXEC';
    if (isWiseupProduct) {
      return 'EXEC';
    }

    const isTeensProduct =
      queryProductSlug?.toLowerCase() === 'wiseup-teens' ||
      query?.product?.toUpperCase() === 'TEENS';
    if (isTeensProduct) {
      return 'TEENS';
    }

    const isVendeCProduct =
      queryProductSlug?.toLowerCase()?.includes('vendec') ||
      query?.product?.toUpperCase() === 'VENDEC';
    if (isVendeCProduct) {
      return 'VC';
    }

    const isMedCofProduct =
      queryProductSlug?.toLowerCase()?.includes('medcof') ||
      query?.product?.toUpperCase() === 'MEDCOF';
    if (isMedCofProduct) {
      return 'MEDCOF';
    }

    const isAtrincaProduct = query?.product?.toUpperCase() === 'ATRINCA';
    if (isAtrincaProduct) {
      return 'ATRINCA';
    }

    const ALLOWED_QUERY_PRODUCTS = [
      'MS',
      'WUP',
      'POWERHOUSE',
      'VC',
      'CCLUB',
      'PERFORMANC',
      'WSP',
      'NUMBERONE',
      'RESOLVEENEM',
      'ESCOLACONQUER',
    ];

    if (ALLOWED_QUERY_PRODUCTS?.includes(query?.product?.toUpperCase())) {
      return queryProductSlug || query?.product?.toUpperCase();
    }

    return 'MS';
  }

  const ALLOWED_PRODUCTS_BY_PRODUCT_SLUG = {
    'C-HUB': 'C-HUB',
    'APROVATOTAL-ESQUADRAO-ELITE': 'APROVATOTAL-ESQUADRAO-ELITE',
    LIVE: 'LIVE',
    'WISEUP-EXEC': 'EXEC',
    // material didático do exec
    'EXEC-MD': 'EXEC',
    'WISEUP-TEENS': 'TEENS',
    // material didático do teens
    'TEENS-MD': 'TEENS',
    WISERGV: 'WISERGV',
    'VENDEC-WISERGV': 'VENDEC-WISERGV',
    'LIBERTE-C': 'LIBERTE-C',
    'CUNNA-EFLM': 'CUNNA-EFLM',
  };

  const ALLOWED_PRODUCTS_BY_DOMAIN = {
    MEUSUCESSO: 'MS',
    WISEUP: 'WUP',
    NUMBERONE: 'NUMBERONE',
    POWERHOUSE: 'POWERHOUSE',
    EUMILITAR: 'EM',
    'VENDE-C': 'VC',
    CCLUB: 'CCLUB',
    'PERFORMAN-C': 'PERFORMANC',
    WISERSP: 'WSP',
    APROVATOTAL: 'APROVATOTAL',
    MEDCOF: 'MEDCOF',
    RESOLVEENEM: 'RESOLVEENEM',
    ESCOLACONQUER: 'ESCOLACONQUER',
    ATRIN: 'ATRINCA',
  };

  return (
    ALLOWED_PRODUCTS_BY_PRODUCT_SLUG[queryProductSlug] ||
    ALLOWED_PRODUCTS_BY_DOMAIN[productFromDomain] ||
    'MS'
  );
}

export const getUserGA = ({ ctx }) => {
  let GACookie = '';

  const isCookiesOnServer =
    !!ctx?.req?.headers?.cookie &&
    cookie?.parse(ctx?.req?.headers?.cookie) &&
    cookie?.parse(ctx?.req?.headers?.cookie)?._ga;

  if (isCookiesOnServer) {
    GACookie = cookie
      ?.parse(ctx.req.headers.cookie)
      ?._ga?.replace(/GA[0-9]{1,3}[.][0-9]{1,3}[.]/g, '');
  } else if (typeof window !== 'undefined') {
    GACookie = jsCookie
      ?.get('_ga')
      ?.replace(/GA[0-9]{1,3}[.][0-9]{1,3}[.]/g, '');
  }

  return GACookie;
};

export const isMozambiqueTheme = ({ query }) => {
  const lang = query?.lang?.toLowerCase();

  return lang === 'pt-mz';
};

export const formatName = (name = '') => {
  const splittedName = name?.replace(/\s{2,}/g, ' ')?.split(' ');
  const formattedName = splittedName
    ?.map((item) => ReturnFirstCapital(item))
    ?.join(' ')
    ?.toString();

  return formattedName;
};

export function getInputsErrors({
  inputId,
  inputType,
  inputValue,
  lang,
  length,
}) {
  function validateInput({ fieldtype, value, lang }) {
    const validateObj = validate.inputValidate(fieldtype, value, lang, length);
    return validateObj;
  }

  const valid = validateInput({
    fieldtype: inputType?.toUpperCase(),
    value: inputValue,
    lang,
  });

  return valid;
}
/**
 * @deprecated
 */
export const getProductOnServerSide = ({ req, res, query }) => {
  const productSlugByProduct = {
    meusucesso: 'meusucesso',
    ms: 'meusucesso',
    wiseup: 'wiseup-online',
    wup: 'wiseup-online',
    powerhouse: 'powerhouse',
    em: 'eumilitar',
    eumilitar: 'eumilitar',
    vc: 'vende-c',
    'vende-c': 'vende-c',
    cclub: 'cclub',
    performanc: 'performan-c',
    'performan-c': 'performan-c',
    wisersp: 'wiser',
    wsp: 'wiser',
    aprovatotal: 'aprovatotal',
    medcof: 'medcof',
    escolaconquer: 'conquer',
  };

  const domain = req
    ? !req?.headers?.domain
      ? req?.headers?.host
      : req?.headers?.domain
    : window?.location?.host;

  const domainSplitted = domain?.split('.')[1];

  const productId =
    productSlugByProduct[domainSplitted || query?.product || 'ms'];

  return productId;
};

export const getTagManagerProduct = (productTheme, productSlug) => {
  const productName = productTheme?.data?.product?.name;

  return productSlug ? `${productName} - ${productSlug}` : productName;
};

export const states = [
  { value: 'AC', label: 'AC' },
  { value: 'AL', label: 'AL' },
  { value: 'AP', label: 'AP' },
  { value: 'AM', label: 'AM' },
  { value: 'BA', label: 'BA' },
  { value: 'CE', label: 'CE' },
  { value: 'DF', label: 'DF' },
  { value: 'ES', label: 'ES' },
  { value: 'GO', label: 'GO' },
  { value: 'MA', label: 'MA' },
  { value: 'MT', label: 'MT' },
  { value: 'MS', label: 'MS' },
  { value: 'MG', label: 'MG' },
  { value: 'PA', label: 'PA' },
  { value: 'PB', label: 'PB' },
  { value: 'PR', label: 'PR' },
  { value: 'PE', label: 'PE' },
  { value: 'PI', label: 'PI' },
  { value: 'RJ', label: 'RJ' },
  { value: 'RN', label: 'RN' },
  { value: 'RS', label: 'RS' },
  { value: 'RO', label: 'RO' },
  { value: 'RR', label: 'RR' },
  { value: 'SC', label: 'SC' },
  { value: 'SP', label: 'SP' },
  { value: 'SE', label: 'SE' },
  { value: 'TO', label: 'TO' },
];

export function formatValueBeforeSend(value = '') {
  return value
    ? value.replaceAll('.', '').replace('-', '').replace('/', '')
    : '';
}
