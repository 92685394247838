import projectConfig from '../../../package.json';
import { InternalLogger, ErrorLevel, AcceptableTags } from '../types';

export class SimpleLoggerAdapter implements Omit<InternalLogger, 'setUser'> {
  captureError(
    errorLevel: ErrorLevel,
    error: Error | string,
    additionalTags?: Record<string, AcceptableTags>,
    additionalInfo?: Record<string, unknown>
  ): void {
    const objectToLog = {
      projectVersion: projectConfig.version,
      errorLevel,
      additionalTags,
      additionalInfo,
      error: error instanceof Error ? error.stack : error,
    };

    let colorCode: string;

    switch (errorLevel) {
      case ErrorLevel.FATAL:
        colorCode = '\x1b[1;31m'; // Bright Red
        break;
      case ErrorLevel.ERROR:
        colorCode = '\x1b[31m'; // Red
        break;
      case ErrorLevel.WARNING:
        colorCode = '\x1b[33m'; // Yellow
        break;
      case ErrorLevel.INFO:
        colorCode = '\x1b[34m'; // Blue
        break;
      case ErrorLevel.DEBUG:
        colorCode = '\x1b[36m'; // Cyan
        break;
      default:
        colorCode = '\x1b[0m'; // Reset to default
        break;
    }

    console.log(`${colorCode}%s\x1b[0m`, JSON.stringify(objectToLog, null, 2));
  }
}
