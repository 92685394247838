export enum Business {
  WISEUP_ONLINE = 'wiseup-online',
  WISEUP_LIVE = 'wiseup-live',
  WISEUP = 'wiseup',
  MEUSUCESSO = 'meusucesso',
  EUMILITAR = 'eumilitar',
  POWERHOUSE = 'powerhouse',
  VENDE_C = 'vende-c',
  PERFORMAN_C = 'performan-c',
  WISER = 'wiser',
  APROVATOTAL = 'aprovatotal',
  MEDCOF = 'medcof',
  ATRINCA = 'atrinca',
  CONQUER_FRANQUIAS = 'conquer-franquias',
  CONQUER_BUSINESS_SCHOOL = 'conquer-business-school',
  CONQUER_INCOMPANY = 'conquer-incompany',
  CONQUER_EDTECH = 'conquer-edtech',
  CONQUER = 'conquer',
  CONQUER_UNNA = 'conquer-unna',
}
